/* DataHub.css */

.container {
  display: flex;
}

.main-content {
  flex: 1; /* Take up remaining space */
  height: fit-content;
}

.datahub {
  width: 100%; /* Adjust this value to match the side menu bar width */
  height: 100%; /* Full height of the viewport */
  background-color: #f4f4f4; /* Example background color */
  border-left: 1px solid #ddd; /* Example border */
  padding: 20px; /* Example padding */
  box-sizing: border-box; /* Ensure padding is included in the width/height */
  overflow: auto;
}

.datahub::-webkit-scrollbar {
  display: none;
}

.datahub h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.datahub p {
  font-size: 1em;
  margin-bottom: 20px;
}

.chart-container {
  height: 300px; /* Constrain the height of the charts */
  margin-bottom: 20px; /* Add some space between charts */
}

@media (max-width: 768px) {
  .container {
    flex-direction: column; /* Change to column layout on smaller screens */
  }

  .datahub {
    width: 100%; /* Full width on smaller screens */
    height: auto; /* Auto height */
    border-left: none; /* Remove border */
    border-bottom: 1px solid #ddd; /* Add border at the bottom */
    padding: 10px; /* Adjust padding */
  }
}
