/* Common Panel Styles */
.panel, .community {
  padding: 20px;
  background-color: transparent;
  border-radius: 8px;
  width: 95%;
  min-width: 300px;
  min-height: 150px;
}

/* User Profile */
.user-profile  {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  width: 100%;
  min-width: 300px;
  min-height: 150px;
  margin-left: 250px;
}

.profile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  min-width: 300px;
  min-height: 150px;
  width: 100%;
}

.profile-header h1 {
  font-size: 26px;
  padding: 5px 10px;
  color: #2C3E50;
  margin-bottom: 15px;
  font-weight: bold;
}

.guest-disabled {
  opacity: 0.5;
  pointer-events: none;
}

.username-badge {
  width: 80%;
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 10px;
  background-color: #3498db;
  color: white;
  text-align: center;
  box-sizing: border-box;
}

.username-badge h2, .username-badge p {
  color: white;
  margin: 5px 0;
}

/* Container for the inventory list */
.inventory-container {
  width: 100%;
  overflow: hidden;
  padding: 20px;
}

/* Scrollable container with snap functionality */
.inventory-scroll-container {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  padding: 20px;
  margin-bottom: 20px;
  padding-right: 200px;
}

/* Grid layout that displays items in a row */
.inventory-grid {
  display: flex;
  gap: 20px;
}

/* Each individual inventory item */
.inventory-item {
  scroll-snap-align: start;
  flex: 0 0 auto;
  width: 200px;
  border: 1px solid #ddd;
  padding: 15px;
  background-color: #fff;
  border-radius: 8px;
  transition: transform 0.3s ease; /* Smooth transition for zoom */
  transform: scale(1); /* Default scale */
}

/* Zoom effect on the focused inventory item */
.inventory-item.focused {
  transform: scale(1.1); /* Scale up the focused card */
  border: 2px solid #3498db; /* Optional highlight for focused item */
}

/* Inventory actions such as input and buttons */
.inventory-actions {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.inventory-buttons {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.delete-button, .share-button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 5px;
  border-radius: 4px;
  cursor: pointer;
}

.delete-button {
  background-color: #e74c3c;
}

/* Inventory options list and other elements */
.inventory-options-list {
  list-style: none;
  padding: 0;
  margin: 10px 0;
}

.inventory-option {
  font-size: 14px;
  margin: 5px 0;
}
/* inbox-grid */

.inbox-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  width: 100%;
  padding: 0px;
  background-color: transparent; /* Set background to transparent */
  border-radius: 10px;
  position: relative; /* Ensure the vertical bar is positioned correctly */
}

.inbox-friends-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  padding: 20px;
  background-color: transparent; /* Set background to transparent */
  border-radius: 10px;
  position: relative; /* Ensure the vertical bar is positioned correctly */
}

.inbox-grid {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1000px; /* Adjust width to make rows wide */
  margin: 0 auto;
  padding: 5px;
}

.inbox-message {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  border: 1px solid #ccc;
  border-radius: 2px;
  background-color: #f7f7f7;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.inbox-message:hover {
  background-color: #e9ecef; /* Similar to Gmail's hover effect */
}

.inbox-message h3 {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin: 0;
  flex-grow: 1;
}

.inbox-message p {
  margin: 0;
  margin-right: 50px;
  color: #777;
}

.delete-button {
  background-color: #e74c3c;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  width: 100px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.delete-button:hover {
  background-color: #ff5252;
}

.delete-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.delete-button.ranked {
  background-color: red;
  cursor: not-allowed;
}

/* Add ranked category styling */
.inbox-message.ranked {
  background-color: #e0e0e0; /* Light gray background */
  border-color: #b0b0b0; /* Gray border color */
  color: #808080; /* Gray text color */
}
/* Action Sections */
.action-section, .custom-categories-grid, .inbox-grid, .rankings-grid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  overflow-x: auto;
  scroll-behavior: smooth;
  width: 100%;
}

.back-to-homepage {
  text-decoration: none;
  color: #3498db;
  font-weight: bold;
  padding: 10px 20px;
  transition: color 0.3s;
}

.back-to-homepage:hover {
  color: #2980b9;
}

/* Custom Categories, Inbox, Rankings */
.category-card, .ranking-card {
  background-color: #ecf0f1;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  /* width: calc(33.333% - 25px); */
  box-sizing: border-box;
  min-width: 300px;
  min-height: 150px;
}
.inbox-message{
  width: 100%;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  /* width: calc(33.333% - 25px); */
  box-sizing: border-box;
  min-width: 300px;
  height: 25px;
}

.category-card:hover, .ranking-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
}

.inbox-message.expiring {
  background-color: #f8d7da;
}

.ranked {
  background-color: #d3d3d3;
  pointer-events: none;
  opacity: 0.6;
}

.ranked .delete-button {
  pointer-events: auto;
}

/* Dropdown Menu */
.toc-list {
  list-style: none;
  padding: 0;
  margin: 20px 0;
}

.toc-list li {
  margin-bottom: 10px;
}

.toc-list a {
  text-decoration: none;
  color: darkslategrey;
  transition: color 0.3s;
}

.toc-list a:hover {
  color: #2980b9;
}

/* Toggle Switch */
.social-mode-toggle-container {
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-mode-toggle {
  transition: opacity 0.3s, visibility 0.3s;
}

.social-mode-toggle label {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.social-mode-toggle input[type="checkbox"] {
  margin-left: 10px;
  width: 40px;
  height: 20px;
  background: #c6c6c6;
  border-radius: 20px;
  cursor: pointer;
  position: relative;
  transition: background 0.3s;
}

.social-mode-toggle input[type="checkbox"]:checked {
  background: #4caf50;
}

.social-mode-toggle input[type="checkbox"]::before {
  content: '';
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: white;
  transition: transform 0.3s;
}

.social-mode-toggle input[type="checkbox"]:checked::before {
  transform: translateX(20px);
}

.social-mode-toggle.hidden {
  opacity: 0;
  visibility: hidden;
}

.social-mode-toggle-container:hover .social-mode-toggle {
  opacity: 1;
  visibility: visible;
}

/* Lists */
ul, li {
  list-style: none;
  padding: 0;
}

li {
  padding: 10px 0;
  border-bottom: 1px solid #bdc3c7;
}

li:last-child {
  border-bottom: none;
}

/* Share Section */
.share-section {
  margin-top: 15px;
  display: flex;
}

.share-input, .community input {
  width: 85%;
  padding: 10px;
  margin-bottom: 12px;
  font-size: 16px;
  border-radius: 25px; /* Curve the edges */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a box shadow */
  border: 1px solid #ccc; /* Add a border */
  outline: none; /* Remove the default outline */
}

.share-button {
  background-color: #3498db;
  color: white;
  padding: 12px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  width: 75%;
  transition: background-color 0.3s;
  height: 60px;
}

.share-button:hover {
  background-color: #2980b9;
}


/* Tier Styles */
.tier {
  margin-top: 12px;
  position: relative;
  padding-left: 10px;
}

.tier::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 5px;
}

.tier h4 {
  font-size: 18px;
  margin-bottom: 8px;
  color: #7f8c8d;
  text-transform: uppercase;
  font-weight: 700;
}

.tier ul {
  padding: 0;
}

.tier li {
  padding: 10px;
  margin-bottom: 8px;
  border-radius: 6px;
  text-align: center;
  background-color: #ecf0f1;
  color: #34495e;
  font-size: 14px;
}

.tier-S::before { background-color: #ffc300; }
.tier-A::before { background-color: #e5e4e2; }
.tier-B::before { background-color: #d2691e; }
.tier-C::before { background-color: #b87333; }
.tier-D::before { background-color: #555555; }
.tier-F::before { background-color: #8b0000; }

/* Netflix-style Scrolling Grid */
.netflix-scrolling-container {
  display: flex;
  gap: 20px;
  overflow-x: auto;
  padding: 20px 0;
  width: 100%;
  white-space: nowrap;
  scroll-behavior: smooth;
}

.netflix-scrolling-container .ranking-card {
  flex: 0 0 auto;
  width: 200px;
  min-height: 300px;
  background-color: #ecf0f1;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.netflix-scrolling-container .ranking-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
}
/* Hide scrollbar for WebKit browsers */
.netflix-scrolling-container::-webkit-scrollbar {
  display: none;
}

/* Side Menu */
.side-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  background-color: #f9f9f9;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  overflow-y: auto;
}

.main-content {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: calc(100% - 250px);
}

.side-menu .username-badge {
  width: 100%;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  background-color: #3498db;
  color: white;
  text-align: center;
}

.side-menu a {
  display: block;
  color: #2C3E50;
  padding: 10px 0;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s;
}

.side-menu a:hover {
  color: #2980b9;
}

.side-menu a.active {
  color: #2980b9;
}


.datahub-container {
  height: 100%; /* Full height of the viewport */
  overflow-y: auto; /* Enable vertical scrolling if content overflows */
  box-sizing: border-box; /* Ensure padding is included in the height */
  width: 100%;
  display: flex;
  justify-content: right;
}

.right-datahub {
  width: 100%; /* Adjust this value to match the side menu bar width */
  height: auto; /* Full height of the viewport */
  background-color: #f4f4f4; /* Example background color */
  box-sizing: border-box; /* Ensure padding is included in the width/height */
  overflow-y: auto; /* Enable vertical scrolling */
  position: relative;
}

.right-datahub h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.right-datahub p {
  font-size: 1em;
  margin-bottom: 20px;
}

.main-content-data {
  display: flex;
  flex-direction: row;
  padding: 20px;
  overflow: hidden;
  width: 100%;
}


/* Hamburger button styles */
.hamburger-button {
  position: fixed;
  top: 10px;
  right: 10px;
  font-size: 30px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1000;
}

/* Right DataHub container styles */
.right-datahub {
  position: fixed;
  top: 0;
  right: -100%;
  width: 80%;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  transition: right 0.3s ease;
  z-index: 999;
}

.right-datahub.visible {
  right: 0;
}

/* DataHub container styles */
.datahub-container {
  padding: 20px;
}


/* Miscellaneous Mobile Optimizations */
@media (max-width: 768px) {
  .category-card, .inbox-message, .ranking-card {
    width: calc(50% - 25px);
  }

  .profile-header {
    flex-direction: column;
    align-items: flex-start;
  }
  .side-menu {
    position: relative;
    width: 100%;
    box-shadow: none;
  }

  .inbox-message {
    position: relative;
    display: flex;
    justify-content: center;
    left: 0px;
    width: 100%;
    border-radius: 0%;
  }
  .user-profile {
    margin-left: 0;
    width: 100%;
  }
  .main-content {
    margin-left: 0;
  }

  .netflix-scrolling-container .ranking-card {
    width: 150px;
  }

  .profile-header h1 {
    font-size: 22px;
  }

  .datahub-container {
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow-y: auto; /* Enable vertical scrolling */
    max-height: 100vh; /* Ensure it doesn't exceed viewport height */
  }

  .right-datahub {
    width: 100%;
  }

  .main-content-data {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .category-card, .inbox-message, .ranking-card {
    width: 100%;
  }
  .inventory-container {
    width: 95%;
    display: flex;
    justify-content: center;
    padding: 20px;
    background-color: transparent; /* Set background to transparent */
    border-radius: 15px;
  }
  
  .inventory-grid {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    grid-template-rows: repeat(auto-fill, 100px);
    gap: 10px;
  }

  .inventory-item {
    background-color: transparent; /* Set background to transparent */
    border: none; /* Remove border */
    border-left: 10px solid #28a745; /* Add vertical bar on the left side */
    border-radius: 8px;
    padding: 15px;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add subtle box shadow */
  }
  
  .inventory-item:hover {
    background-color: #f1f1f1; /* Similar to Gmail's hover effect */
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Enhance box shadow on hover */
  }
  
  .inventory-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .inventory-category-title {
    font-size: 1.2em;
    color: black;
    cursor: pointer;
  }
  
  .inventory-options-list {
    margin-top: 10px;
    list-style: none;
    padding: 0;
  }
  
  .inventory-option {
    background-color: transparent;
    padding: 8px;
    margin-bottom: 5px;
    border-radius: 5px;
    color: black;
  }
  
  .inventory-actions {
    margin-top: 15px;
  }
  
  .inventory-share-input {
    width: 85%;
    padding: 8px;
    border-radius: 5px;
    background-color: lightgray;
    border: 1px solid lightgray;
    color: #b9bbbe;
  }
  
  .inventory-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  
  .inventory-button {
    padding: 8px 12px;
    border-radius: 5px;
    background-color: #7289da;
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .inventory-button.delete {
    background-color: #ff5c5c;
  }
  
  .inventory-button.delete:hover {
    background-color: #e63946;
  }
  
  .inventory-button.share:hover {
    background-color: #5b6eae;
  }
  
  .inventory-button:disabled {
    background-color: #555;
    cursor: not-allowed;
  }


  .netflix-scrolling-container .ranking-card {
    width: 94%;
  }

  .panel, .community {
    padding: 10px;
    width: 100%;
  }

  .side-menu {
    display: none;
  }
  .user-profile{
    margin-left: 0;
    width: 100%;
  }

  .profile-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .main-content {
    margin-right: 0;
    width: 100%;
  }

  .inbox-grid{
    padding: 0;
    width: 100%;
  }

  .inbox-grid .delete-button {
    position: absolute;
    display: flex;
    justify-content: center;
    right: 0px;
    padding: 11px 8px;
    width: 100px;
    border-radius: 0%;
  }
  .inbox-message {
    position: relative;
    display: flex;
    justify-content: center;
    left: 0px;
    width: 100%;
    border-radius: 0%;
  }

  .inbox-message h3 {
    font-size: 16px;
    
  }

  .custom-section {
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .custom-categories-grid {
    display: flex;
    flex-direction: row;
    flex: 0 0 auto;
    overflow-x: auto;
    scroll-behavior: smooth;
    max-height: 500px;
    padding: 0;
    width: 100%;
  }
  
  .custom-categories-grid::-webkit-scrollbar {
    display: none; /* Hide scrollbar for WebKit browsers */
  }
  
  .custom-categories-grid-item {
    flex: 0 0 auto; /* Prevent items from shrinking */
    margin-right: 10px; /* Space between items */
    width: 250px; /* Fixed width for items */
    height: auto; /* Fixed height for items */
    background-color: #ecf0f1;
    padding: 20px;
    border-radius: 12px;
    transition: transform 0.3s, box-shadow 0.3s;
    /* width: calc(33.333% - 25px); */
  }

  h2 {
    font-size: 18px;
  }
}