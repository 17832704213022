/* Global Styles */
body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
}

h1 {
  color: #333;
}
/* Banner */
.banner {
  width: 100%;
  background: linear-gradient(135deg, #0080ff, #e0e0e0);
  padding: 10px 0;
  text-align: center;
  display: flex;
  align-items: center; /* Center items vertically */
  justify-content: space-between; /* Evenly space items */
}

.logo {
  height: 50px;
  margin-left: 20px; /* Add some margin to the left */
}

.profile-pic {
  width: 40px;
  height: 40px;
  margin-right: 8px;
}

.search-bar {
  flex-grow: 1; /* Allow the search bar to grow */
  max-width: 600px; /* Set a max width */
  margin: 0 auto; /* Center the search bar */
  padding: 5px;
}

.search-bar input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 25px; /* Curve the edges */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a box shadow */
  border: 1px solid #ccc; /* Add a border */
  outline: none; /* Remove the default outline */
}

.homepage-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.categories-section {
  margin: 20px 0;
  max-width: 95%;
  position: relative;
}

.categories, .options, .tiers {
  margin: 0px 0;
  overflow-x: hidden;
}

/* Buttons */
button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 2px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.disabled-category {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
  opacity: 0.7;
}

/* List Styling */
.options ul, .tier ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.options li, .tier li {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.options li:last-child, .tier li:last-child {
  border-bottom: none;
}

/* Download Button */
#download-button {
  margin-top: 20px;
  background-color: #28a745;
}

#download-button:hover {
  background-color: #218838;
}

/* Tier List Styling */
#tier-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 500px;
  overflow-x: hidden;
  margin: 0 auto;
}

.tier {
  width: 100%;
  margin-bottom: 10px;
}

.tier h3 {
  margin: 0;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
  background-color: #f4f4f4;
  border-radius: 5px;
  text-align: center;
}

.tier ul {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
}

.tier li {
  flex: 1 1 30%;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  background-color: #fafafa;
  border-radius: 5px;
  text-align: center;
  margin: 5px;
}

.tier li:last-child {
  border-bottom: none;
}

/* Footer */
.footer {
  margin-top: 20px;
  text-align: center;
  width: 100%;
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 5px;
}

/* Custom Categories */
.custom-categories {
  margin: 20px 0;
}

.custom-categories h2 {
  color: #333;
  margin-bottom: 10px;
}

.custom-categories button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 2px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.custom-categories button:hover {
  background-color: #0056b3;
}

.custom-category ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.custom-category li {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  background-color: #fafafa;
  border-radius: 5px;
  text-align: center;
  margin: 5px 0;
}

.header .profile-link {
  margin-left: 20px;
  color: white;
  text-decoration: none;
  font-weight: bold;
}

.header .profile-link:hover {
  text-decoration: underline;
}

/* Exit Button Styling */
.exit-button {
  background: #007bff;
  border: none;
  cursor: pointer;
  position: relative;
  z-index: 1000;
  padding: 10px;
  border-radius: 5px;
  color: white;
  font-weight: bold;
}

.exit-icon {
  width: 20px;
  height: 20px;
  position: relative;
  top: 2px;
  filter: brightness(0) invert(1); 
  transition: transform 0.2s ease;
}

.exit-button:hover .exit-icon {
  transform: scale(1.1);
}



.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #343a40;
  color: white;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.header .username {
  animation: slideIn 0.4s ease-in;
}
.header .profile-link{
  animation: fadeIn 2s ease-in;
}

/* Streaming Banner */
.streaming-banner {
  width: 100%;
  background-color: #f8f9fa;
  padding: 10px 0;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  margin-top: 10px;
  color: #343a40; 
  font-weight: bold;
  font-size: 1.2em;
}

.streaming-text {
  display: inline-block;
  padding: 0 2rem;
  animation: scroll 10s linear infinite;
}


@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* Media Queries for Responsiveness */

/* Small devices (phones) */
@media (max-width: 600px) {
  #tier-list {
    max-width: 100%;
    padding: 10px;
  }

  .tier li {
    flex: 1 1 100%;
    padding: 5px;
    margin: 2px;
  }

  .search-bar input{
    font-size: 14px;
    width: 200px;
  }

  .search-bar {
    flex-grow: 1; /* Allow the search bar to grow */
    margin: 0 auto; /* Center the search bar */
    padding: 5px;
    margin-right: 50px;
  }
  .logo {
    visibility: hidden;
    height: 40px;
    margin-left: 10px; /* Add some margin to the left */
  }
  .profile-pic {
    width: 40px;
    height: 40px;
    margin-right: 8px;
  }

  .banner {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .streaming-banner{
    visibility: hidden;
  }

  .categories-section {
    margin: 10px 0;
  }

  button {
    padding: 8px 15px;
  }

  .header {
    flex-direction: column;
  }

  .logo {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .streaming-banner {
    font-size: 1em;
  }

  .exit-button {
    padding: 8px;
    margin-left: 25px;
  }
}

/* Medium devices (tablets) */
@media (min-width: 601px) and (max-width: 1024px) {
  #tier-list {
    max-width: 80%;
  }

  .tier li {
    flex: 1 1 45%;
  }

  button {
    padding: 9px 18px;
  }

  .streaming-banner {
    font-size: 1.1em;
  }
}

/* Large devices (desktops) */
@media (min-width: 1025px) {
  #tier-list {
    max-width: 70%;
  }

  .tier li {
    flex: 1 1 30%;
  }

  .streaming-banner {
    font-size: 1.2em;
  }
}