/* Results and Friend Grid */
.community .results .result-item, 
.community .friends-grid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  background-color: transparent;
  border-radius: 8px;
  width: 100%;
}


.community .results .result-item {
  margin-bottom: 10px;
}

/* Make the search avatars smaller */
.avatar-small {
  width: 30px;
  height: 30px;
  border-radius: 50%; /* Circular image */
  margin-right: 10px;
  object-fit: cover; /* Ensures the image covers the space evenly */
}

.community .friends-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 15px; 
  justify-content: center; 
  width: 100%;
}

/* Friend item specific styles */
.community .friend-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  background-color: #fff;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.community .friend-item img {
  width: 60px; 
  height: 60px; 
  border-radius: 50%; 
  margin-bottom: 10px;
  border: 2px solid #ddd; 
}

/* Friend username styling */
.community .friend-item p {
  margin: 0;
  font-size: 14px;
  color: #333;
}

/* No Friends Message */
.no-friends {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  text-align: center;
  margin: 20px 0;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  color: #2C3E50;
}



.share-button, .delete-button {
  background-color: transparent;
  color: #3498db; /* Change text color to match the border */
  padding: 12px;
  border: 1px solid #3498db; /* Add border with the desired color */
  border-radius: 6px;
  cursor: pointer;
  width: 50%;
  transition: background-color 0.3s, color 0.3s; /* Add color transition */
}

.share-button:hover, .delete-button:hover {
  background-color: #3498db; /* Add background color on hover */
  color: white; /* Change text color on hover */
}

/* Delete Button */
.delete-button {
  background-color: transparent;
  color: red;
  padding: 8px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  width: 100px;
  transition: background-color 0.3s;
}

.delete-button:hover {
  background-color: #c0392b;
}

/* Modal Styles */
.modal {
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Modal Content */
.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.confirm-button, .cancel-button {
  padding: 10px 20px;
  border-radius: 4px;
  margin: 0 10px;
  font-size: 14px;
}

.confirm-button {
  background-color: #ff5252;
  color: white;
}

.cancel-button {
  background-color: #2196f3;
  color: white;
}

/* Confirmation Message Styles */
.confirmation-message {
  margin-top: 10px;
  padding: 10px;
  border-radius: 4px;
  background-color: #d4edda; /* Light green background */
  color: #155724; /* Dark green text */
  border: 1px solid #c3e6cb; /* Green border */
  text-align: center;
  transition: opacity 0.5s ease-out;
  opacity: 1;
}

.confirmation-message.fade-out {
  opacity: 0;
}

/* Error Message Styles */
.error-message {
  margin-top: 10px;
  padding: 10px;
  border-radius: 4px;
  background-color: #f8d7da; /* Light red background */
  color: #721c24; /* Dark red text */
  border: 1px solid #f5c6cb; /* Red border */
  text-align: center;
  transition: opacity 0.5s ease-out;
  opacity: 1;
}

.error-message.fade-out {
  opacity: 0;
}


/* Request Grid - Ensure it takes up the entire row */
.requests-grid {
  display: flex;
  flex-direction: column; /* Stack the request items vertically */
  width: 100%;
}

/* Request Item - Full width */
.request-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 8px;
  margin-bottom: 10px;
  width: 95%;
}

/* Smaller Avatar */
.request-item img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 15px;
}

/* Username styling */
.request-item p {
  margin: 0;
  font-size: 14px;
  color: #333;
  flex-grow: 1;
}

/* Accept Button */
.request-item button {
  background-color: #3498db;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin-right: 10px; /* Space between buttons */
  transition: background-color 0.3s;
}

.request-item button:hover {
  background-color: #2980b9;
}

/* Remove Button */
.request-item .remove-button {
  background-color: #e74c3c;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.request-item .remove-button:hover {
  background-color: #c0392b;
}