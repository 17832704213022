/* LoginModal.css */
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 400px;
    width: 100%;
  }
  
  .modal-content h2 {
    margin-bottom: 20px;
  }
  
  .modal-content form {
    display: flex;
    flex-direction: column;
  }
  
  .modal-content form input {
    margin-bottom: 10px;
    padding: 10px;
  }
  
  .modal-content form button {
    padding: 10px;
  }
  
  .modal-content button {
    margin-top: 10px;
    background-color: #28a745;
    color: white;
    padding: 10px;
    border: none;
    cursor: pointer;
  }