.suggestion-section {
    margin: 20px;
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 10px;
    text-align: center;
  }
  
  .input-section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .input-box {
    padding: 10px;
    width: 60%;
    margin-right: 10px;
    border: 2px solid #ccc;
    border-radius: 8px;
    font-size: 16px;
  }
  
  .add-button, .add-category-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .add-button:hover, .add-category-button:hover {
    background-color: #0056b3;
  }
  
  .option-list {
    list-style: none;
    padding: 0;
    margin: 20px 0;
  }
  
  .option-item {
    padding: 10px;
    margin: 5px 0;
    background-color: #007bff;
    color: white;
    border-radius: 8px;
  }