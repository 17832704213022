/* AuthForm.css */

.auth-form-container {
    max-width: 500px;
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 4px 8px #0000001a;
    background-color: #fff;
    position: relative;
    top: 50px;
  }
  
  .auth-form-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .auth-form-container form {
    display: flex;
    flex-direction: column;
  }
  
  .auth-form-container input {
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .auth-form-container button {
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-bottom: 10px; /* Add margin to separate buttons */
  }
  
  .auth-form-container button:hover {
    background-color: #0056b3;
  }
  
  .auth-form-container .guest-button {
    background-color: #28a745;
  }
  
  .auth-form-container .guest-button:hover {
    background-color: #218838;
  }