/* src/Modal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: #fff;
    padding: 30px;
    border-radius: 12px;
    text-align: center;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
    max-width: 500px;
    width: 100%;
    position: relative;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.modal-content p {
    color: #333;
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 25px;
}

.modal-close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    background-color: transparent;
    border: none;
    color: #ff4d4d;
    font-size: 24px;
    cursor: pointer;
    transition: color 0.3s ease;
}

.modal-close-button:hover {
    color: #ff0000;
}

.modal-overlay.show .modal-content {
    transform: translateY(-10px);
    opacity: 1;
}

.modal-overlay.hide .modal-content {
    transform: translateY(10px);
    opacity: 0;
}